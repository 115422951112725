// The order of these values should not be altered, since P4P requested it to be ordered this way for the action subject
// dropdown in action-detail.component.html
export enum IdentityTypes {
  Client = 'client',
  Werkgever = 'werkgever',
  Doorverwijzer = 'doorverwijzer',
  Vraagbaak = 'vraagbaak',
  Poort = 'poort',
  Admin = 'admin',
  Coach = 'coach',
  Financial = 'financial',
  Freelancer = 'freelancer',
  Nakijker = 'nakijker',
  Planner = 'planner',
  Relatiebeheerder = 'relatiebeheerder',
  TrajectAssistent = 'trajectassistent',
  DossierAfronder = 'dossierafronder',
}

export const internalIdentityTypes = [
  IdentityTypes.Admin,
  IdentityTypes.TrajectAssistent,
  IdentityTypes.Freelancer,
  IdentityTypes.Coach,
  IdentityTypes.Planner,
  IdentityTypes.Financial,
  IdentityTypes.Vraagbaak,
  IdentityTypes.Nakijker,
  IdentityTypes.Poort,
  IdentityTypes.Relatiebeheerder,
  IdentityTypes.DossierAfronder,
];

export const externalIdentityTypes = [
  IdentityTypes.Doorverwijzer,
  IdentityTypes.Werkgever,
  IdentityTypes.Client
];

export const mainRoles = [
  IdentityTypes.Admin,
  IdentityTypes.TrajectAssistent,
  IdentityTypes.Freelancer,
  IdentityTypes.Coach,
  IdentityTypes.Planner,
  IdentityTypes.Financial
];

export const extraRoles = [
  IdentityTypes.Vraagbaak,
  IdentityTypes.Nakijker,
  IdentityTypes.Poort,
  IdentityTypes.Relatiebeheerder,
  IdentityTypes.DossierAfronder,
];

export const coachExtraRoles = [
  IdentityTypes.Vraagbaak,
  IdentityTypes.Nakijker,
  IdentityTypes.Relatiebeheerder,
];

export const trajectAssistentExtraRoles = [
  IdentityTypes.Planner
];

export const plannerExtraRoles = [
  IdentityTypes.Poort,
  IdentityTypes.DossierAfronder,
];

export enum CommunicationIdentityTypes {
  Doorverwijzer = 'doorverwijzer',
  Werkgever = 'werkgever',
  Client = 'client',
  Other = 'other',
  OtherRequester = 'otherRequester',
  Owner = 'owner'
}
