import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {InputComponent} from '../input-field/input.component';
import {Editor} from 'primeng/editor';

@Component({
  selector: 'app-p-editor',
  templateUrl: './p-editor.component.html',
  styleUrls: ['./p-editor.component.scss'],
  // make the css defined in this component global so the styles are also used in the p-editor (instead of ng-deep)
  encapsulation: ViewEncapsulation.None
})
export class PEditorComponent extends InputComponent<string> implements OnInit, AfterViewChecked, OnChanges {
  @Input() public maxlength: number = Number.MAX_SAFE_INTEGER;

  public valid: boolean = !this.required;

  @ViewChild('pEditor')
  public editor: Editor;

  private _editorInitialized: boolean = false;

  ngAfterViewChecked() {
    // workaround for missing focus/blur events (https://github.com/quilljs/quill/issues/2186#issuecomment-533401328)
    // in addition, it appears that the editor.getQuill call doesn't always immediately return the editor instance
    // as such, we double-check, and make sure to only add the even listeners once
    if (!this._editorInitialized && this.editor?.getQuill()?.editor) {
      this.editor.getQuill().editor.scroll.domNode.addEventListener('focus', this.onFocus.bind(this));
      this.editor.getQuill().editor.scroll.domNode.addEventListener('blur', this.onBlur.bind(this));
      this._editorInitialized = true;
    }
  }

  override ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && (changes['data'].currentValue !== changes['data'].previousValue)) {
      this.validate();
    }
  }

  ngOnInit(): void {
    this.validate();
  }

  setMaxStringLength(value: string): void {
    this.valid = value?.length <= this.maxlength;
    this.dataChange.emit(value);
  }

  onBlur(): void {
    this.validate();
    this.validChanged(this.valid);
  }

  validate() {
    this.dataEmpty = this.checkEmptyData();
    this.valid = !(this.required && this.dataEmpty);
  }

  override checkEmptyData(): boolean {
    const html = document.createElement('div');
    html.innerHTML = this.data;

    return !this.data || !html.innerText;
  }

  onFocus(): void {
    this.onFocusChange.emit();
  }
}
